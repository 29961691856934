import { UserContext } from "@hygo/shared/contexts";
import { Auth } from "@hygo/shared/models";
import { Error } from "@hygo/web/ui-components";
import { AppWrapper } from "@hygo/web/ui-components";
import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import styled from "styled-components";

const Wrapper = styled.main`
	height: 100%;
`;

interface PublicRouteProps {
	allowedMobilePaths?: string[];
}

const PublicRoutes = ({ allowedMobilePaths }: PublicRouteProps): JSX.Element => {
	const { authError, status } = useContext(UserContext);
	const location = useLocation();
	const showOnMobile = location.pathname.split("/").some((item) => allowedMobilePaths.includes(item));

	if (status === Auth.LOGGED_IN) {
		return <Navigate replace to="/dashboard" />;
	}

	if (status === Auth.NEED_SETUP) {
		return <Error type={authError} />;
	}

	return (
		<AppWrapper showOnMobile={showOnMobile}>
			<Wrapper>
				<Outlet />
			</Wrapper>
		</AppWrapper>
	);
};
export default PublicRoutes;
