import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { ModalsContext } from "@hygo/shared/modals";
import { Feature, FieldsManagerEvents } from "@hygo/shared/models";
import { CropsScreenContext, OADContext } from "@hygo/web/contexts";
import { OADStepEnum } from "@hygo/web/models";
import { OnboardingCropsModal } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

import CropsScreen from "./CropsScreen";
import { CropsContainerProps, CropsScreenProps } from "./screen.types";

const CropsContainer = ({ version }: CropsContainerProps): JSX.Element => {
	const { currentMode, fieldsRef, handleFieldSelection, selectedFields, updateMode } = useContext(CropsScreenContext);
	const { createFarm, deleteFarm, updateFarm } = useApi();
	const { getOnboardingKeyCrops, loggedInSmag, setStepCookie } = useContext(OADContext);
	const { showModal } = useContext(ModalsContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { activeFields, admin, crops, defaultFarm, farmerSelected, farms, loadFarms, loading, updateDefaultFarm } =
		useContext(UserContext);

	const navigate = useNavigate();
	const hasTasks = useFeature(Feature.TASKS);
	const hasSmagFields = loggedInSmag && activeFields?.some((f) => f.smagCropZoneUid);

	const goToSmagImport: CropsScreenProps["goToSmagImport"] = () => {
		navigate("/import/smag");
	};

	const onUpdateDefaultFarm: CropsScreenProps["updateDefaultFarm"] = async (farmId, newFarms, handleNewFields) => {
		handleFieldSelection({
			centerMap: true,
			field: null,
			overrideActiveFields: null,
			overrideNewSelectedFields: [],
			selection: true
		});
		logAnalyticEvent(FieldsManagerEvents.updateDefaultFarm, { farm: farms?.find((f) => f.id === farmId) });
		const fetchedFields = await updateDefaultFarm(farmId, newFarms);
		if (handleNewFields)
			handleFieldSelection({
				centerMap: true,
				field: null,
				overrideActiveFields: fetchedFields,
				overrideNewSelectedFields: [],
				selection: true
			});
	};

	useEffect(() => {
		if (getOnboardingKeyCrops()) {
			showModal(OnboardingCropsModal, {
				onboardingKey: getOnboardingKeyCrops(),
				onConfirm: (): void => {
					setStepCookie({ key: getOnboardingKeyCrops(), value: OADStepEnum.SHOW_ONBOARDING_TASKS });
					hasTasks && navigate("/interventions");
				}
			});
		}
	}, [getOnboardingKeyCrops, showModal, navigate, setStepCookie, hasTasks]);

	return (
		<CropsScreen
			admin={admin}
			createFarm={createFarm}
			crops={crops}
			currentMode={currentMode}
			defaultFarm={defaultFarm}
			deleteFarm={deleteFarm}
			farmerSelected={farmerSelected}
			farms={farms}
			fields={activeFields}
			fieldsRef={fieldsRef}
			goToSmagImport={goToSmagImport}
			handleFieldSelection={handleFieldSelection}
			hasSmagFields={hasSmagFields}
			loadFarms={loadFarms}
			loading={loading}
			loggedInSmag={loggedInSmag}
			selectedFields={selectedFields}
			updateDefaultFarm={onUpdateDefaultFarm}
			updateFarm={updateFarm}
			updateMode={updateMode}
			version={version}
		/>
	);
};
export default CropsContainer;
