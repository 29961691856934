import { useApi } from "@hygo/shared/api";
import { useResetPassword } from "@hygo/shared/feature-account-settings";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import ResetPasswordScreen from "./ResetPasswordScreen";
import { ResetPasswordFormInputs, ResetPasswordScreenProps } from "./screen.types";

const ResetPasswordContainer = (): JSX.Element => {
	const { resetPassword } = useApi();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<ResetPasswordScreenProps["loading"]>(false);
	const [passwordUpdated, setPasswordUpdated] = useState<ResetPasswordScreenProps["passwordUpdated"]>(false);
	const methods = useForm<ResetPasswordFormInputs>({ mode: "onChange" });
	const { rules } = useResetPassword();
	const { token } = useParams();

	const onSubmit: ResetPasswordScreenProps["onSubmit"] = async ({ password }) => {
		setLoading(true);
		try {
			await resetPassword({ password, resetToken: token });
			setPasswordUpdated(true);
		} catch (e) {
			methods.setError("password", {
				message: t("snackbar.resetPassword.error"),
				type: "custom"
			});
			throw e;
		} finally {
			setLoading(false);
		}
	};

	const onGoBack: ResetPasswordScreenProps["onGoBack"] = (): void => {
		navigate("/");
	};

	return (
		<ResetPasswordScreen
			loading={loading}
			methods={methods}
			onGoBack={onGoBack}
			onSubmit={onSubmit}
			passwordUpdated={passwordUpdated}
			rules={rules}
		/>
	);
};

export default ResetPasswordContainer;
