export enum OADKeyEnum {
	GEOFOLIA_ONBOARDING_STEP = "geofoliaOnboardingStep",
	SMAG_ONBOARDING_STEP = "smagOnboardingStep"
}

export enum OADStepEnum {
	DONE = "DONE",
	SHOW_ONBOARDING_CROPS = "SHOW_ONBOARDING_CROPS",
	SHOW_ONBOARDING_TASKS = "SHOW_ONBOARDING_TASKS"
}
