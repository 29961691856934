import { ChangeEvent, MutableRefObject } from "react";
import { useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";

interface TextInputProps {
	autoCapitalize?: "off" | "words";
	autoComplete?: string;
	autoFocus?: boolean;
	className?: string;
	customRef?: MutableRefObject<HTMLTextAreaElement>;
	disabled?: boolean;
	flex?: boolean;
	hint?: string;
	iconLabel?: JSX.Element;
	label?: string;
	name: string;
	onCustomChange?: (value: string) => void;
	placeholder?: string;
	placeholderIcon?: { icon: JSX.Element; position: "left" | "right" };
	rows?: number;
	rules?: Record<string, unknown>;
	type?: string;
	unit?: string;
}

const LabelWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	gap: 4px;
`;

const Label = styled.label`
	display: block;
	font-size: var(--14px);
`;

const Wrapper = styled.div<{ $error: boolean; $flex: boolean }>`
	font-size: var(--14px);
	font-family: "Nunito Bold";
	color: ${(props) => (props.$error ? "var(--gaspacho-100)" : "inherit")};
	width: 100%;
	display: ${(props) => (props.$flex ? "flex" : "unset")};
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	@media (max-width: 740px) {
		font-size: var(--16px);
	}
`;

const FieldWrapper = styled.div<{ $placeholderIconPosition: "left" | "right" }>`
	position: relative;
	flex: 1;

	svg {
		position: absolute;
		left: ${(props) => (props.$placeholderIconPosition === "left" ? "8px" : "auto")};
		right: ${(props) => (props.$placeholderIconPosition === "right" ? "8px" : "auto")};
		top: 0;
		transform: translateY(50%);
	}
`;

const InputWrapper = styled.div`
	position: relative;
`;

const Unit = styled.h4`
	position: absolute;
	right: 8px;
	top: 0;
	transform: translateY(50%);
`;

export const Input = styled.input<{
	$error: boolean;
	$placeholderIconPosition: "left" | "right";
	disabled: boolean;
}>`
	width: 100%;
	height: 40px;
	border: 1px solid var(${(props) => (props.$error ? "--gaspacho-100" : "--night-10")});
	color: ${(props) => {
		if (props?.disabled) return "var(--night-50)";
		return props?.$error ? "var(--gaspacho-100)" : "var(--night-100)";
	}};
	background-color: ${(props) => (props?.disabled ? "var(--night-5)" : "unset")};
	border-radius: 4px;
	padding: 0;
	padding-left: ${(props) => (props.$placeholderIconPosition === "left" ? "32px" : "8px")};
	padding-right: ${(props) => (props.$placeholderIconPosition === "right" ? "32px" : "8px")};
	outline: none;
	&:focus {
		border: 1px solid var(--lake-100);
	}
	&::placeholder {
		font-size: var(--14px);
		font-family: "Nunito Bold";
		color: var(--night-50);
	}
	${(props) =>
		props.disabled &&
		css`
			+ ${Unit} {
				color: var(--night-50);
			}
		`}
`;

const Hint = styled.p`
	color: var(--night-50);
`;

const Error = styled.p`
	color: var(--gaspacho-100);
`;

const TextInput = ({
	autoCapitalize,
	autoComplete,
	autoFocus,
	className,
	customRef,
	disabled,
	flex,
	hint,
	iconLabel,
	label,
	name,
	onCustomChange,
	placeholder,
	placeholderIcon,
	rows,
	rules = {},
	type = "text",
	unit
}: TextInputProps): JSX.Element => {
	const { getFieldState, register } = useFormContext();
	const registerOptions = onCustomChange
		? { ...rules, onChange: (e: ChangeEvent<HTMLInputElement>) => onCustomChange(e.target.value) }
		: rules;
	const { onBlur, onChange, ref } = register(name, registerOptions);
	const error = getFieldState(name)?.error;
	const hasError = !!error?.message;
	return (
		<Wrapper $error={hasError} $flex={flex} className={className}>
			{label && (
				<LabelWrapper>
					{iconLabel}
					<Label htmlFor={name}>{label}</Label>
					{hint && <Hint>{hint}</Hint>}
				</LabelWrapper>
			)}
			<FieldWrapper $placeholderIconPosition={placeholderIcon?.position}>
				<InputWrapper>
					<Input
						$error={hasError}
						$placeholderIconPosition={placeholderIcon?.position}
						as={type === "textarea" ? "textarea" : null}
						autoCapitalize={autoCapitalize}
						autoComplete={autoComplete}
						autoFocus={autoFocus}
						disabled={disabled}
						id={name}
						name={name}
						onBlur={onBlur}
						onChange={onChange}
						placeholder={placeholder}
						ref={(e) => {
							ref(e);
							if (customRef) customRef.current = e;
						}}
						rows={rows}
						type={type}
					/>
					{placeholderIcon?.icon}
					{unit && <Unit>{unit}</Unit>}
				</InputWrapper>
				{hasError && <Error>{error.message}</Error>}
			</FieldWrapper>
		</Wrapper>
	);
};

export default TextInput;
