import { Season } from "@hygo/shared/models";
import { useCallback, useMemo } from "react";

interface useSeasonResult {
	findTaskSeason: (startTime: Date) => Season;
	stepSeasons: Season[];
}

interface useSeasonsProps {
	startDate?: Date;
	withNextSeason: boolean;
}

export const useSeasons = ({
	startDate = new Date(2019, 1, 1, 0, 0, 0),
	withNextSeason
}: useSeasonsProps): useSeasonResult => {
	const computeSeasonYear = useCallback(
		(date: Date): number => (date.getMonth() > 7 ? date.getFullYear() + 1 : date.getFullYear()),
		[]
	);

	const stepSeasons = useMemo(() => {
		const startSeasonYear = computeSeasonYear(startDate);
		const currentSeasonYear = computeSeasonYear(new Date());

		const accumulator = withNextSeason ? 2 : 1;
		return Array(currentSeasonYear + accumulator - startSeasonYear)
			.fill(startSeasonYear)
			.map((year, index) => {
				const newYear = year + index;
				return {
					isCurrent: newYear === currentSeasonYear,
					startAfter: new Date(newYear - 1, 8, 1),
					startBefore: new Date(newYear, 7, 31, 23, 59, 59, 999),
					year: newYear
				};
			});
	}, [startDate, withNextSeason, computeSeasonYear]);

	const findTaskSeason: useSeasonResult["findTaskSeason"] = useCallback(
		(startTime) => {
			return stepSeasons?.find((season) => computeSeasonYear(startTime) === season.year);
		},
		[stepSeasons, computeSeasonYear]
	);

	return { findTaskSeason, stepSeasons };
};
