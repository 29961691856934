import { setUserId } from "@amplitude/analytics-browser";
import { setDeviceId } from "@amplitude/analytics-browser";
import { AmplitudeContext } from "@hygo/shared/amplitude";
import { AxiosContext } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { Auth, ENV, Feature, SmartlookEvents, User } from "@hygo/shared/models";
import { generateUUID } from "@hygo/shared/utils";
import * as Sentry from "@sentry/react";
import { createContext, useCallback, useContext, useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import smartlookClient from "smartlook-client";

export const AuthContext = createContext({});

declare global {
	interface Window {
		// eslint-disable-next-line
		Chargebee: any;
	}
}

interface AuthProviderProps {
	children: JSX.Element | JSX.Element[];
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
	const { chargebee, loginWithLocalToken, setChargebee, status, userToMonitor } = useContext(UserContext);
	const { boot, shutdown } = useIntercom();
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { updateUuid } = useContext(AxiosContext);
	const exitMonitoring = useCallback(async (): Promise<void> => {
		Sentry.setUser(null);
		setUserId(undefined);
		shutdown();
		if (smartlookClient.initialized()) smartlookClient.disable();
	}, [shutdown]);

	const initMonitoring = useCallback(
		async (userParams: User): Promise<void> => {
			Sentry.setUser({
				admin: !!userParams?.admin?.userId,
				email: userParams?.email || userParams?.admin?.email,
				firstName: userParams?.firstName || userParams?.admin?.firstName,
				id: userParams?.id?.toString() || userParams?.admin?.userId?.toString(),
				lastName: userParams?.lastName || userParams?.admin?.lastName,
				plan: userParams?.plan?.planId
			});
			boot({
				createdAt: new Date(userParams?.createdAt)?.getTime(),
				customAttributes: {
					coopId: userParams?.coopId || userParams?.admin?.coopId,
					hveMode: userParams?.setup?.hveMode,
					location: userParams?.location?.label,
					planId: userParams?.plan?.planId,
					premiumRequestDate: userParams?.premiumRequestDate,
					smagStatus: userParams?.smagStatus
				},
				email: userParams?.email || userParams?.admin?.email,
				name: `${userParams?.firstName || userParams?.admin?.firstName} ${
					userParams?.lastName || userParams?.admin?.lastName
				}`,
				userId: userParams?.id?.toString() || userParams?.admin?.userId?.toString()
			});
			if (
				!userParams?.tester &&
				userParams?.plan?.features?.includes(Feature.FARM_WEATHER) &&
				userParams?.id &&
				process.env.NX_PUBLIC_ENV === ENV.PRODUCTION
			) {
				smartlookClient.record({
					emails: true,
					forms: true,
					numbers: true
				});
				smartlookClient.restart();
				smartlookClient.init("0b9ec064e80d6bd3bc9029987c5b47cb9ec94b56", { cookies: true, region: "eu" });
				smartlookClient.identify(userParams?.id?.toString(), {
					email: userParams?.email,
					name: `${userParams?.firstName} ${userParams?.lastName}`,
					package: userParams?.plan?.planId
				});
				smartlookClient.getData(() =>
					logAnalyticEvent(SmartlookEvents.sessionUrl, { session_url: smartlookClient.playUrl })
				);
			} else {
				if (smartlookClient.initialized()) smartlookClient.disable();
			}
		},
		[logAnalyticEvent, boot]
	);

	useEffect(() => {
		const getLocalAuthToken = async (): Promise<void> => {
			const storedToken = localStorage.getItem("hygoCookie");
			await loginWithLocalToken(storedToken);
		};
		if (status === Auth.LOGGED_OUT) getLocalAuthToken();
	}, [status, loginWithLocalToken]);

	useEffect(() => {
		userToMonitor ? initMonitoring(userToMonitor) : exitMonitoring();
	}, [userToMonitor, initMonitoring, exitMonitoring]);

	useEffect(() => {
		if (status === Auth.LOGGED_IN && !chargebee) {
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.src = "https://js.chargebee.com/v2/chargebee.js";
			script.async = true;
			script.onload = () => {
				window.Chargebee.init({
					site: process.env.NX_PUBLIC_ENV === ENV.PRODUCTION ? "alvie" : "alvie-test"
				});
				setChargebee(window.Chargebee.getInstance());
			};
			document.body.appendChild(script);
		}
	}, [setChargebee, chargebee, status]);

	useEffect(() => {
		const getAndStoreUuid = async (): Promise<void> => {
			const uuid = localStorage.getItem("uuid") || generateUUID();
			setDeviceId(uuid);
			localStorage.setItem("uuid", uuid);
			updateUuid(uuid);
		};
		getAndStoreUuid();
	}, [updateUuid]);

	return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
