import { UserContext } from "@hygo/shared/contexts";
import { Auth, Feature } from "@hygo/shared/models";
import { AppWrapper, Navbar } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router";
import styled from "styled-components";

import routes from "../../navbar.routes";

const Wrapper = styled.main`
	height: calc(100% - 68px);
`;

interface FarmerRoutesProps {
	feature: Feature;
}

const FarmerRoutes = ({ feature }: FarmerRoutesProps): JSX.Element => {
	const { admin, coopUsers, farmerSelected, logout, signInAsUser, status, superAdmin, user } =
		useContext(UserContext);

	const hasFeature = useFeature(feature);

	// Allow admins to have access to the Parcellaire page
	if (status !== Auth.LOGGED_IN || (!farmerSelected && feature !== Feature.FARM_WEATHER)) {
		return <Navigate replace to="/" />;
	}

	if (farmerSelected && !hasFeature) {
		return <Navigate replace to="/pricing" />;
	}

	return (
		<AppWrapper>
			<Navbar
				admin={admin}
				coopUsers={coopUsers}
				farmerSelected={farmerSelected}
				logout={logout}
				routes={routes}
				signInAsUser={signInAsUser}
				superAdmin={superAdmin}
				user={user}
			/>
			<Wrapper>
				<Outlet />
			</Wrapper>
		</AppWrapper>
	);
};
export default FarmerRoutes;
