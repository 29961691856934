import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { countryMapping } from "@hygo/shared/constants";
import { UserContext } from "@hygo/shared/contexts";
import { useCoops } from "@hygo/shared/hooks";
import { AccountEvents, Coop, Country, Language, SnackbarType, WindSpeedUnity } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { emailRegEx } from "@hygo/shared/utils";
import { t } from "i18next";
import { parsePhoneNumber } from "libphonenumber-js/max";
import { NationalNumber } from "libphonenumber-js/types";
import { useContext, useEffect, useMemo, useState } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface AccountFormInputs {
	autocomplete: string;
	coop: string;
	email: string;
	firstName: string;
	language: Language;
	lastName: string;
	locationId: string;
	phoneCountrySelector: Country;
	principalPhone: NationalNumber;
	windSpeedUnity: WindSpeedUnity;
}

interface useAccountSettingsResult {
	coops: Coop[];
	loading: boolean;
	methods: UseFormReturn<AccountFormInputs>;
	onEditPhoneCountrySelector: (c: Country) => void;
	rules: Record<keyof AccountFormInputs, RegisterOptions>;
	updateLocation: (v: { autocomplete: string; locationId: string }) => void;
	updateUser: (values: AccountFormInputs) => Promise<void>;
}

export const useAccountSettings = (): useAccountSettingsResult => {
	const { i18n } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { fetchUser, user } = useContext(UserContext);
	const { coops, matchingCoop: userCoop } = useCoops({ coopId: user?.coopId });
	const { showSnackbar } = useContext(SnackbarContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);

	const { patchUser } = useApi();

	const defaultValues: AccountFormInputs = useMemo(() => {
		const parsedPhoneNumber = user?.principalPhone && parsePhoneNumber(user?.principalPhone);
		const countryCode = (parsedPhoneNumber?.country as Country) || Country.FR;
		const phoneNumber = parsedPhoneNumber?.nationalNumber;
		return {
			autocomplete: user?.location?.label,
			coop: userCoop?.id?.toString() || null,
			email: user.email,
			firstName: user.firstName,
			language: user.language,
			lastName: user.lastName,
			locationId: undefined as undefined,
			phoneCountrySelector: countryCode,
			principalPhone: phoneNumber,
			windSpeedUnity: user?.windSpeedUnity
		};
	}, [userCoop, user]);

	const methods = useForm({ defaultValues, mode: "onChange" });

	const updateLocation: useAccountSettingsResult["updateLocation"] = ({ autocomplete, locationId }) => {
		logAnalyticEvent(AccountEvents.updateLocation, { location: autocomplete });
		methods.setValue("locationId", locationId, { shouldDirty: true, shouldValidate: true });
		methods.setValue("autocomplete", autocomplete, { shouldDirty: true, shouldValidate: true });
	};

	const rules: useAccountSettingsResult["rules"] = {
		autocomplete: {
			required: {
				message: t("inputs.location.errors.required"),
				value: true
			}
		},
		coop: undefined,
		email: {
			pattern: {
				message: t("inputs.email.errors.invalid"),
				value: emailRegEx
			},
			required: {
				message: t("inputs.email.errors.required"),
				value: true
			}
		},
		firstName: {
			maxLength: {
				message: t("inputs.firstName.errors.invalid"),
				value: 40
			},
			minLength: {
				message: t("inputs.firstName.errors.invalid"),
				value: 2
			},
			required: {
				message: t("inputs.firstName.errors.required"),
				value: true
			}
		},
		language: undefined,
		lastName: {
			maxLength: {
				message: t("inputs.lastName.errors.invalid"),
				value: 40
			},
			minLength: {
				message: t("inputs.lastName.errors.invalid"),
				value: 2
			},
			required: {
				message: t("inputs.lastName.errors.required"),
				value: true
			}
		},
		locationId: undefined,
		phoneCountrySelector: undefined,
		principalPhone: undefined,
		windSpeedUnity: {
			required: {
				message: t("inputs.windSpeedUnity.errors.required"),
				value: true
			}
		}
	};

	const onEditPhoneCountrySelector: useAccountSettingsResult["onEditPhoneCountrySelector"] = (value) => {
		logAnalyticEvent(AccountEvents.updatePhoneCountry);
		methods.setValue("phoneCountrySelector", value, { shouldDirty: true, shouldValidate: true });
		methods.setValue("principalPhone", null, { shouldValidate: false });
	};

	const updateUser: useAccountSettingsResult["updateUser"] = async (formValues) => {
		setLoading(true);

		const values = {
			coopId: parseInt(formValues.coop, 10) || null,
			email: formValues.email,
			firstName: formValues.firstName,
			language: formValues.language,
			lastName: formValues.lastName,
			locationId: formValues.locationId,
			principalPhone: parsePhoneNumber(
				`+${countryMapping[formValues.phoneCountrySelector].phone.callingCode}${formValues.principalPhone}`,
				{
					extract: false
				}
			).format("E.164"),
			windSpeedUnity: formValues.windSpeedUnity
		};

		try {
			await patchUser(values);
			await fetchUser();
			await i18n.changeLanguage(formValues.language);
			logAnalyticEvent(AccountEvents.updateUser);
			showSnackbar(t("snackbar.updateAccount.success"), SnackbarType.SUCCESS);
		} catch (e) {
			if (e?.response?.data?.code === "invalidFirstName") {
				methods.setError("firstName", {
					message: t("inputs.firstName.errors.invalid"),
					type: "custom"
				});
			} else if (e?.response?.data?.code === "invalidLastName") {
				methods.setError("lastName", {
					message: t("inputs.lastName.errors.invalid"),
					type: "custom"
				});
			} else if (e?.response?.data?.code === "wrongPhoneNumberFormat") {
				methods.setError("principalPhone", {
					message: t("inputs.principalPhone.errors.invalid"),
					type: "custom"
				});
			} else {
				showSnackbar(t("snackbar.updateAccount.error"), SnackbarType.ERROR);
				throw e;
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	return {
		coops,
		loading,
		methods,
		onEditPhoneCountrySelector,
		rules,
		updateLocation,
		updateUser
	};
};
