import { AmplitudeContext } from "@hygo/shared/amplitude";
import { Route } from "@hygo/web/models";
import { useFeature } from "flagged";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router";
import styled from "styled-components";

const StyledNavLink = styled(NavLink)`
	text-align: center;
	text-decoration: none;
	padding: 8px 16px 0 16px;
	height: 100%;

	justify-content: space-around;
	svg {
		margin: auto;
	}
	h5 {
		color: var(--night-50);
		white-space: nowrap;
	}
	path:not([class]) {
		fill: var(--night-50);
	}
	&:hover,
	&[aria-current] {
		h5 {
			color: var(--lake-100);
		}
		path:not([class]) {
			fill: var(--night-100);
		}
	}
	&[aria-current] {
		background-color: var(--smoke-100);
		border-bottom: 4px solid var(--lake-100);
	}
`;

interface NavItemProps {
	route: Route;
	superAdmin: boolean;
}

const NavItem = ({ route, superAdmin }: NavItemProps): JSX.Element => {
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const hasFeature = useFeature(route.feature || "empty");
	const showRoute = (): boolean => {
		if (route.admin) return superAdmin;
		if (route.feature) return !!hasFeature;
		return null;
	};

	const onClick = (): void => {
		if (route.event) logAnalyticEvent(route.event);
	};

	const { t } = useTranslation();
	if (!showRoute()) return null;
	return (
		<StyledNavLink onClick={onClick} to={route.path}>
			{route.icon}
			<h5>{t(`routes.navbar.${route.id}`)}</h5>
		</StyledNavLink>
	);
};

export default NavItem;
