export enum AccountEvents {
	addMorePlots = "Clic sur “Ajouter mon parcellaire en passant par un essai gratuit”",
	clickManageSubscription = "Clic sur 'Gérer mon abonnement'",
	deleteCoop = "Supprime un distributeur",
	editLocation = "Clic sur “Ajouter une commune”",
	editPassword = "Clic sur la modification du mot de passe",
	goToPricing = "Clic sur “Consulter les offres HYGO”",
	updateCoop = "Modification du distributeur",
	updateEmail = "Modification de l'email",
	updateFirstName = "Modification du prénom",
	updateLastName = "Modification du nom",
	updateLocation = "Modification de la commune de l’exploitation",
	updatePassword = "Valide la modification du mot de passe",
	updatePhone = "Modification du numéro de téléphone",
	updatePhoneCountry = "Modifie l’indicatif de téléphone",
	updateUser = "Clic sur “Enregistrer” depuis mes informations"
}

export enum AdvancedEquipmentEvents {
	editSoilAcidity = "Clic sur la modification de l’acidité du sol",
	editWaterAcidity = "Clic sur la correction de l’acidité de l’eau",
	editWaterHardness = "Modification de la correction de la dureté de l’eau",
	editWaterTankBottomPercentage = "Clic sur la correction du volume d’eau",
	validateAdvancedEquipment = "Enregistrement d’une configuration avancée"
}

export enum ArvalisDeviceEvents {
	addStation = "Ajout d’une station"
}

export enum ArvalisDeviceSelectorEvents {
	deleteStation = "Suppression d’une station",
	updateStation = "Modification d'une station"
}

export enum AuthenticationLandingEvents {
	goSignIn = "Clic sur “Connexion avec email”",
	goSignUp = "Clic sur “Créer mon compte”"
}

export enum AuthEvents {
	deletion = "Suppression de compte",
	login = "Connexion",
	logout = "Déconnexion",
	logoutFromError = "Déconnexion suite à une erreur"
}

export enum CheckSetupEvents {
	needUpdate = "Arrivée sur l'écran de blocage 'Mettre à jour l'application'",
	noDefaultFarm = "Arrivée sur l'écran de blocage 'Pas de ferme pas défaut'",
	noFields = "Arrivée sur l'écran de blocage 'Pas de parcelles dans cette ferme'",
	noNetwork = "Arrivée sur l'écran de blocage 'Pas de réseau'",
	noPlan = "Arrivée sur l'écran de blocage 'Pas d'abonnement trouvé'",
	noWeather = "Arrivée sur l'écran de blocage 'Erreur de chargement météo'",
	onGoingMaintenance = "Arrivée sur l’écran de blocage 'Maintenance en cours'"
}

export enum DetectedTasksEvents {
	clickSelectSlot = "Clic sur 'Choisir ce créneau' depuis l'écran de tâches détectées",
	clickTaskCard = "Clic sur une détection depuis l'écran de tâches détectées'"
}

export enum DiscoverLandingEvents {
	clickOnPlan = "Clic sur un plan premium"
}

export enum DrawerEvents {
	clickGoToNotifications = "Clic sur notifications",
	clickHelp = "Clic sur Guide d'utilisation",
	goToDiscoverLanding = "Clic sur 'Découvrir HYGO'",
	goToEquipment = "Clic sur 'Mes équipement et sol'",
	goToSettings = "Clic sur 'Paramètres'",
	goToUserSelector = "Clic sur changer d'utilisateur"
}

export enum EquipmentEvents {
	firstSetup = "Première configuration de l'équipement réalisée (sol & buses)",

	goToAdvancedEquipment = "Clic sur 'Configuration avancée'",
	updateSoil = "Modification du sol"
}

export enum FieldPhasesEvents {
	editHarvest = "Clic sur la modification de la date de récolte",
	editSowing = "Clic sur la modification de la date de semis"
}

export enum FieldSettingsEvents {
	editFieldName = "Clic sur Renommer une parcelle",
	editPlantationDate = "Modification de la date de plantation",
	editYield = "Modification du rendement",
	editZone = "Clic sur “Modifier la zone” depuis Mes parcelles",
	goBackToFields = "Clic sur “Terminer”",
	goToMileosPhases = "Clic sur “Stades de développement” depuis la parcelle",
	goToPhases = "Clic sur “Dates de semi & récolte” depuis la parcelle"
}

export enum FieldsEvents {
	clickAddField = "Clic sur “Ajouter une parcelle” depuis Mes parcelles",
	clickGoBackHome = "Clic sur “Non” à la demande d’ajout de parcelles supplémentaires",
	clickMoreFields = "Clic sur “Oui” à la demande d’ajout de parcelles supplémentaires",
	fromGeofolia = "Clic sur “Geofolia” depuis l’ajout de parcelle",
	fromMesParcelles = "Clic sur “Mes Parcelles” depuis l’ajout de parcelle",
	fromTelepac = "Clic sur “Telepac” depuis l’ajout de parcelle",
	goToField = "Sélection d’une parcelle depuis “Mes parcelles”",
	selectFromMap = "Clic sur “Sélectionner sur la carte” depuis l’ajout de parcelle",
	updateCrops = "Mise à jour des parcelles depuis l'écran Mes Parcelles"
}

export enum FieldsManagerEvents {
	clickDraw = "Clic sur “Dessiner” depuis l’ajout d’une parcelle",
	clickDrawNewField = "Clic sur “Nouvelle parcelle”",
	clickEditZone = "Clic sur “Redessiner la zone”",
	clickRPG = "Clic sur “Sélectionner” depuis l’ajout d’une parcelle",
	clickShowTasks = "Clic sur “Voir interventions” depuis une parcelle sélectionnée dans “Parcellaire”",
	createFarm = "Création d'une ferme",
	createFields = "Clic sur “Créer ces parcelles”",
	deleteFarm = "Suppression d’une ferme",
	deleteField = "Suppression d’une parcelle",
	editFieldCharacteristics = "Clic sur modifier les caractéristique de parcelle",
	editFieldName = "Clic sur Renommer une parcelle",
	editFieldPhases = "Clic sur modifier le cycle agricole",
	selectFieldFromManager = "Sélection d’une parcelle depuis le manager",
	selectFieldFromMap = "Sélection d’une parcelle depuis la carte",
	updateDefaultFarm = "Sélection d’une ferme depuis le parcellaire",
	updateFarm = "Modification d’une ferme",
	updateField = "Modification des infos d’une parcelle",
	updateFieldsCrops = "Sélection d’une culture depuis la modification de culture",
	validateFieldsCrops = "Valider la sélection depuis la modification de culture"
}

export enum HomeEvents {
	clickAddIrrigation = "Clic sur “Irrigation” depuis la modal de création de traitement",
	clickAddMixture = "Bouton 'Ajouter une bouillie' depuis l'Accueil",
	clickAddTreatment = "Clic sur “Protection” depuis la modal de création de traitement",
	clickContactUs = "Clic sur Intercom depuis l’accueil",
	clickDeleteMixture = "Clic sur “Supprimer le traitement” depuis la modal bouillie",
	clickDoneTask = "Clic sur réalisé",
	clickGoNetatmo = "Clic sur “En Direct” depuis l’accueil",
	clickGoToMileos = "Clic sur Mileos",
	clickGoToRadar = "Clic sur l'écran radar depuis l'Accueil",
	clickGoToRealTime = "Clic sur l'écran temps réel depuis l'Accueil",
	clickHome = "Clic sur prévisionnel",
	clickLoadMore = "Clique sur 'Charger plus'",
	clickMeteo = "Clic sur la météo depuis l'Accueil",
	clickMixtureDay = "Clic sur le détail d’un jour depuis une bouillie",
	clickOnDetectedFab = "Clic sur “Interventions à valider” depuis l’accueil",
	clickOpenMixtureMenu = "Ouverture de la modal d’action des bouillies",
	clickShowHomologatedDoses = "Clic sur “Voir les préconisations d’application” depuis la modal bouillie",
	clickTask = "Clic sur une intervention depuis un traitement",
	clickUpdateMixture = "Clic sur 'Modifier le traitement' depuis la modal bouillie",
	createTaskFromFields = "Clic sur la création d’intervention depuis les parcelles d’un traitement sur l’accueil",
	editTaskFields = "Modification des parcelles de choix des parcelles du traitement à valider",
	goToMixtureFields = "Navigation vers l'écran parcelles d'une bouillie depuis l’accueil",
	initDoneTask = "Clic sur 'Ajouter une intervention réalisée' depuis la modal bouillie",
	maxMixturesAttempts = "Clic sur “C’est compris” depuis l’écran maximum de bouillie",
	selectFarm = "Clic sur sélection de ferme",
	showCreatedTask = "Clic sur Voir depuis le feedback de création d’intervention sous un traitement",
	swipeToRefresh = "Recharge les traitements “pull to refresh” de l’accueil",
	validateSlot = "Clic sur Choisir ce créneau depuis la modal de choix de créneau",
	validateTaskFields = "Clic sur Valider depuis le choix des parcelles du traitement à valider"
}

export enum ImportEvents {
	clickImportSmag = "Clic sur “Import via Smag”",
	clickImportTelepac = "Clic sur “Import depuis un fichier”",
	importFromFieldsLinking = "Valide l'import depuis l'écran d'association des parcelles",
	retryImport = "Réessayer l’import sur à une erreur"
}

export enum ImportSmagEvents {
	editFields = "Modification des parcelles depuis l'écran 'Import Smag'"
}

export enum ImportSmagRotationEvents {
	validate = "Valide l’import SMAG mobile"
}

export enum IrrigationReportEvents {
	deleteIrrigation = "Suppression de l'irrigation depuis l'écran Résumé d'irrigation'",
	goBack = "Clic sur retour depuis l’écran de résumé d'irrigation",
	goToFields = "Redirection vers la modification des parcelles depuis l'écran Résumé d'irrigation",
	goToSlot = "Redirection vers la modification de créneau depuis l'écran Résumé d'irrigation",
	leaveIrrigation = "Quitte la création d'irrigation depuis l’écran résumé d'irrigation'",
	saveReport = "Sauvegarde de l'irrgation depuis l'écran Résumé d'irrigation"
}

export enum MileosAdvisorDetailsEvents {
	sendInvitation = "Clic sur “Envoyer une invitation”"
}

export enum MileosAdvisorEvents {
	addAdvisor = "Clic sur “Ajouter un conseiller",
	showAdvisor = "Clic sur un conseiller Mileos"
}

export enum MileosAdvisorFieldsEvents {
	addField = "Ajout d'une parcelle attribuée à un conseiller",
	removeField = "Suppression d'une parcelle attribuée à un conseiller"
}

export enum MileosEvents {
	clickAddInfo = "Clic sur ”Ajouter une information” depuis une parcelle Mileos",
	clickAddIrrigation = "Clic sur “Irrigation” depuis la modal d’ajout d’informations",
	clickAddMildiou = "Clic sur “Observation de mildiou” depuis la modal d’ajout d’informations",
	clickAddProtection = "Clic sur “Protection” depuis la modal d’ajout d’informations",
	clickEditPhases = "Clic sur “Stades de développement” depuis la modal d’ajout d’informations",
	clickMissingFieldsSetup = "Clic sur “Des parcelles ne sont pas configurées” depuis Mileos",
	clickSetupFields = "Clic sur “Configurer mes parcelles” depuis l’empty state",
	goToChart = "Clic sur “Graphique” d’indices",
	goToFieldSettings = "Accès aux paramètres d’une parcelle depuis Mileos",
	goToRainFall = "Clic sur “Corriger” depuis le détail des indices Mileos",
	goToSettings = "Clic sur paramètres Mileos",
	ignoreAdvice = "Ignorer un traitement",
	navigateInIndices = "Modification de la période observée depuis le détail des indices Mileos",
	showIndicesModal = "Clic sur le détail des indices Mileos"
}

export enum MileosMilidouEvents {
	addMildiou = "Clic sur “Ajouter une observation” depuis les observations Mildiou",
	editMildiou = "Modifier une observation depuis les observations Mildiou"
}

export enum MileosPhasesEvents {
	editDevelopmentPhases = "Modification d’un stade de développement"
}

export enum MileosRainFallEvents {
	editRainFall = "Modifier la valeur de pluie Mileos"
}

export enum MileosSettingsEvents {
	clickCampaignExport = "Clic sur “Exporter ma campagne”",
	clickSendSms = "Active / Désactive les alertes SMS Mileos",
	goToAdvisors = "Clic sur “Gérer mes conseillers”"
}

export enum MileosVarietySelectorEvents {
	editVariety = "Modification de la variété"
}

export enum MixtureEquipmentEvents {
	editDebit = "Modification du debit de la tâche depuis l'écran Sélection d'équipement d'une bouillie",
	editNozzle = "Modification de la buse de la tâche depuis l'écran Sélection d'équipement d'une bouillie",
	editSprayer = "Modification du pulvérisateur de la tâche depuis l'écran Sélection d'équipement d'une bouillie"
}

export enum MixtureEvents {
	clickCreateMixture = "Création d'une bouillie",
	clickRisk = "Clic sur un risque",
	clickShowTank = "Clic sur “Voir le calcul”",
	clickUpdateMixtureFromHome = "Modification des produits depuis une bouillie",
	clickUpsale = "Clic sur “Demander une démo” depuis le détail d’un traitement",
	duplicateMixture = "Doublon de traitement",
	editProducts = "Modification des produits depuis l'écran de création de bouillies",
	goBack = "Clic sur retour depuis le détail d’un traitement",
	goToEquipment = "Clic sur l’équipement depuis depuis le détail d’un traitement",
	goToFields = "Navigation vers l’écran parcelles d’une bouillie",
	openSlot = "Clic sur la modification de la durée de créneau depuis un traitement",
	updateSlot = "Modification de la durée du créneau d’un traitement",
	validate = "Clic sur “Intervenir sur ce créneau”"
}

export enum MixtureFieldsEvents {
	goBack = "Clic sur retour depuis l’écran Sélection de parcelles d'une bouillie"
}

export enum MixtureInfosEvents {
	clickNotice = "Clic sur une fiche fabricant",
	clickOnCrop = "Clic sur les détail d’usage d’une culture",
	clickOnSearchBar = "Clic sur la barre de recherche de culture depuis l’écran des usages"
}

export enum NavbarEvents {
	goToAccount = "Clic sur “Mes infos & abonnement”",
	goToExport = "Clic sur l’onglet ”Export”",
	goToFieldsManager = "Clic sur l’onglet “Parcellaire”",
	goToImport = "Clic sur l’onglet “Importer”",
	goToOAD = "Clic sur l'onglet “Mes outils connectés”",
	goToSprayers = "Clic sur l’onglet Pulvérisateurs",
	goToTasks = "Clic sur l’onglet “Interventions”",
	loginAsUser = "Sélection d’un utilisateur"
}

export enum NetatmoRainEvents {
	centerLocation = "Clic sur sa localisation depuis l’écran Météo en direct",
	clickBetaButton = "Clic sur “En développement” depuis l’écran Météo en direct",
	clickHistory = "Clic sur “Historique",
	clickNextDayRain = "Clic sur pluie “Aujourd’hui” depuis l’écran Météo en direct",
	clickNextHourRain = "Clic sur pluie “Dans l’heure” depuis l’écran Météo en direct",
	goBack = "Clic sur retour depuis l’écran Météo en direct",
	pressStation = "Clic sur une station",
	selectNewDataType = "Choix d'un nouveau type de données à afficher"
}

export enum NotificationEvents {
	clickOnDetectedTask = "Clic sur 'Passage détecté'",
	clickOnMileosCurativeFungicide = "Clic sur une alerte de préconisation de traitementement curatif de Miléos",
	clickOnMileosEradicatingFungicide = "Clic sur une alerte de préconisation traitement éradicant de Miléos",
	clickOnMileosTreatmentNeeded = "Clic sur une alerte traitement de Miléos",
	clickOnSprayerEnabled = "Clic sur 'Votre pulvérisateur a démarré'",
	clickOnTask = "Clic sur une intervention depuis la notif de dégradation des conditions"
}

export enum NozzleEvents {
	addNozzle = "Ajout d’une buse",
	deleteNozzle = "Suppression d’une buse",
	updateNozzle = "Modification d’une buse"
}

export enum OADEvents {
	clickLoginToSmag = "Clic sur “Me connecter” sur l'OAD Smag"
}

export enum OnboardingEvents {
	onboardingEnd = "Fin de l'onboarding",
	onboardingStart = "Début de l'onboarding général",
	skipOnboarding = "Passe l’onboarding"
}

export enum PlanPresentationEvents {
	clickTryHygoPremium = "Clic sur 'Démarrer un essai gratuit'",
	playVideo = "Lecture de la vidéo"
}

export enum RadarEvents {
	clickLegend = "Clic sur “Légende” depuis le radar",
	goBackFromRadar = "Clic sur retour depuis l’écran radar",
	pauseRadar = "Mettre en pause le radar"
}

export enum RealTimeEvents {
	selectSprayer = "Changement de pulvérisateur depuis le temps réel"
}

export enum RPGEvents {
	selectRPGField = "Sélection d’un contour",
	validateZone = "Clic sur “Valider la zone”"
}

export enum SettingsEvents {
	switchHveMode = "Activer/désactiver le mode expert"
}

export enum SignInEvents {
	forgotPassword = "Clic sur “Mot de passe oublié”",
	goBackFromLogin = "Retour depuis le flow de connexion",
	login = "Clic sur “Connexion” depuis le flow de connexion"
}

export enum SignupEvents {
	clickConditions = "Clic sur “CGU/CGV” depuis la création de compte",
	createAccount = "Clic sur “Créer mon compte” depuis la création de compte",
	goBack = "Clic sur retour depuis la création de compte",
	goNextStep = "Clic sur “Suivant” depuis la création de compte",
	selectBusinessSector = "Clic sur “Secteur d’activité” depuis la création de compte",
	selectCoop = "Clic sur “Distributeur” depuis la création de compte",
	selectCountry = "Clic sur “Pays” depuis la création de compte",
	selectLocation = "Clic sur “Commune” depuis la création de compte",
	selectPhoneCountry = "Clic sur l’indicatif du téléphone depuis la création de compte"
}

export enum SmartlookEvents {
	sessionUrl = "Lancement de la session Smartlook"
}

export enum SprayerEvents {
	addNozzle = "Ajout d'une buse depuis l'écran Pulvérisateurs",
	createSprayer = "Ajout d’un pulvérisateur",
	deleteNozzle = "Suppression d'une buse depuis l'écran Pulvérisateurs",
	deleteSprayer = "Suppression d’un pulvérisateur",
	updateNozzle = "Modification d'une buse depuis l'écran Pulvérisateurs",
	updateSprayer = "Modification d’un pulvérisateur"
}

export enum TankEvents {
	clickKeepDoses = "Clic sur Garder mes doses depuis la modal de modulation",
	clickMaxModulation = "Clic sur Modulation HYGO depuis la modal de modulation",
	clickMinModulation = "Clic sur Aucune modulation depuis la modal de modulation",
	editDose = "Modification de la dose d’un produit depuis le calcul en cuve",
	editEquipment = "Clic sur la modification d’équipement depuis l’écran de calcul en cuve",
	editUnit = "Modification de l’unité d’affichage du calcul en cuve",
	goBack = "Retour depuis l’écran de calcul en cuve",
	openModulationModal = "Clic sur la modulation appliquée depuis la calcul en cuve",
	saveTask = "Clic sur Enregistrer l’intervention depuis l’écran de calcul en cuve",
	sendAsText = "Clic sur Envoi par texte depuis l’envoi de résumé",
	sendResume = "Clic sur Envoyer le résumé depuis l’écran de calcul en cuve",
	showIntercom = "Clic sur l’assistance Intercom depuis la calcul en cuve"
}

export enum TaskEquipmentEvents {
	editDebit = "Modification du debit de la tâche depuis l'écran Sélection d'équipement d'une intervention",
	editNozzle = "Modification de la buse de la tâche depuis l'écran Sélection d'équipement d'une intervention",
	editSprayer = "Modification du pulvérisateur de la tâche depuis l'écran Sélection d'équipement d'une intervention"
}

export enum TaskFieldsEvents {
	clickOnConfirmFieldAreaModal = "Modification de la surface traitée",
	clickOnTreatedArea = "Clic sur la surface traitée",
	editFields = "Modification des parcelles depuis l'écran 'Sélection de parcelles'",
	filterFieldsByProducts = "Activer/désactiver le filtre par culture autorisées",
	goBack = "Clic sur retour depuis l’écran Sélection de parcelles",
	goBackFromTreatment = "Clic sur retour depuis l’étape “Quel traitement ?”",
	selectMixture = "Sélection d’un traitement existant depuis l’étape “Quel traitement ?”",
	selectOtherTreatment = "Clic sur “Autre traitement” depuis l’étape “Quel traitement ?”",
	selectSlot = "Modification de la durée du créneau",
	validateFields = "Clic sur le bouton Confirmer depuis l’écran Sélection de parcelles"
}

export enum TaskProductsEvents {
	clickMissingProduct = "Clic sur Confirmer depuis l’écran produit manquant",
	clickOnDiscoverHygoPremium = "Clic sur Découvrir Hygo Premium depuis la modale du pH",
	clickOnPhInfo = "Clic sur Produit sensible au pH dans l’écran de création des bouillies",
	clickOnRecommendation = "Clic sur recommandation depuis l'écran Sélection de produits",
	editProducts = "Modification des produits depuis l'écran 'Sélection de produits'",
	editTarget = "Modification de la cible depuis un traitement",
	goToMixtureInfos = "Clic sur les doses homologuées depuis la modal de saisie de dose",
	toggleDoseType = "Clic sur le choix de la dose en litre",
	validateProducts = "Clic sur bouton 'Confirmer' depuis l'écran Sélection de produits"
}

export enum TaskReportEvents {
	deleteTask = "Suppression de la tâche depuis l'écran Résumé de Pulvérisation",
	editDebit = "Modification du débit depuis l'écran Résumé de pulvérisation",
	editModulation = "Activation/Désactivation de la modulation depuis l'écran Résumé de pulvérisation",
	editNotes = "Modification des notes depuis l'écran Résumé de pulvérisation",
	editNozzle = "Modification de la buse depuis l'écran Résumé de pulvérisation",
	editSprayer = "Modification du pulvérisateur depuis l'écran Résumé de pulvérisation",
	goBack = "Clic sur retour depuis l’écran de résumé d’intervention",
	goToFields = "Redirection vers la modification des parcelles depuis l'écran Résumé de pulvérisation",
	goToMileosInfos = "Clic sur “Infos produits & rémanence” depuis une intervention",
	goToProducts = "Redirection vers la modification des produits depuis l'écran Résumé de pulvérisation",
	goToSlot = "Redirection vers la modification de créneau depuis l'écran Résumé de pulvérisation",
	goToTargets = "Redirection vers la modification des cibles depuis l'écran Résumé de pulvérisation",
	leaveTask = "Quitte la création d’intervention depuis l’écran Résumé de pulvérisation",
	openModulationModal = "Clic sur la modulation appliquée depuis la résumé d’intervention",
	saveReport = "Sauvegarde de la tâche depuis l'écran Résumé de pulvérisation",
	updateMileosInfos = "Modification de la rémanence"
}

export enum TasksEvents {
	clickTaskDetails = "Clic sur le détail d’une intervention",
	deleteTask = "Suppression d’une intervention",
	exportTasksFileEdi = "Export des intervention en .edi",
	exportTasksFileXsl = "Export des intervention en .xlsx",
	filterTasksWithField = "Sélection d’une parcelle depuis “Interventions”",
	updateDefaultFarmFromTasks = "Sélection d’une ferme depuis les interventions",
	updateSeasonFromTasks = "Sélection d’une saison depuis les interventions"
}

export enum TasksListEvents {
	clickAddTask = "Clic sur “+” depuis l’écran du réalisé",
	clickCenterMap = "Clic sur l’icône de centrage de la carte dans les travaux réalisés",
	clickHelp = "Clic sur “Assistance” depuis l’écran du réalisé",
	clickIrrigation = "Sélection d'une irrigation",
	clickMapField = "Clic sur une parcelle depuis la carte des travaux réalisés",
	clickTask = "Sélection d'une tâche",
	exportSmagTask = "Export de la tâche vers Smag",
	onChangeSeason = "Sélection d’une saison",
	selectSeason = "Clic sur le sélecteur de saison depuis l’écran du réalisé",
	unselectField = "Clic sur “Désélectionner” depuis l’écran du réalisé"
}

export enum TaskSlotEvents {
	clickOnDay = "Sélection d’un autre jour depuis le détail d’un traitement",
	clickParamsHelper = "Clic sur Quels sont ces paramètres ?",
	clickSpecificConditions = "Clic sur conditions spécifiques pour les afficher"
}

export type AmplitudeEvent =
	| AccountEvents
	| AdvancedEquipmentEvents
	| ArvalisDeviceEvents
	| ArvalisDeviceSelectorEvents
	| AuthenticationLandingEvents
	| AuthEvents
	| CheckSetupEvents
	| DetectedTasksEvents
	| DiscoverLandingEvents
	| DrawerEvents
	| EquipmentEvents
	| FieldPhasesEvents
	| FieldSettingsEvents
	| FieldsEvents
	| FieldsManagerEvents
	| HomeEvents
	| ImportEvents
	| ImportSmagEvents
	| ImportSmagRotationEvents
	| IrrigationReportEvents
	| MileosAdvisorDetailsEvents
	| MileosAdvisorEvents
	| MileosAdvisorFieldsEvents
	| MileosEvents
	| MileosMilidouEvents
	| MileosPhasesEvents
	| MileosRainFallEvents
	| MileosSettingsEvents
	| MileosVarietySelectorEvents
	| MixtureEquipmentEvents
	| MixtureEvents
	| MixtureFieldsEvents
	| MixtureInfosEvents
	| NavbarEvents
	| NetatmoRainEvents
	| NotificationEvents
	| NozzleEvents
	| OADEvents
	| OnboardingEvents
	| PlanPresentationEvents
	| RadarEvents
	| RealTimeEvents
	| RPGEvents
	| SettingsEvents
	| SignInEvents
	| SignupEvents
	| SmartlookEvents
	| SprayerEvents
	| TankEvents
	| TaskEquipmentEvents
	| TaskFieldsEvents
	| TaskProductsEvents
	| TaskReportEvents
	| TasksEvents
	| TasksListEvents
	| TaskSlotEvents;
