import { Country, Suggestion } from "@hygo/shared/models";
import { debounce } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import styled from "styled-components";

import TextInput from "../TextInput";

const SuggestionsWrapper = styled.div<{ $width: number }>`
	right: 0;
	width: ${(props) => props.$width}px;
	position: absolute;
	max-height: 120px;
	background-color: var(--white-100);
	border-radius: 4px;
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	overflow: scroll;
	cursor: pointer;
	display: none;
	flex-direction: column;
`;

const Wrapper = styled.div`
	position: relative;
	&:focus-within {
		${SuggestionsWrapper} {
			display: flex;
		}
	}
`;

const SuggestionItem = styled.h5`
	padding: 8px;
	&:hover {
		background-color: var(--smoke-100);
	}
`;

interface PlacesAutocompleteProps {
	countryCode: Country;
	flex?: boolean;
	getPlaceSuggestions: (v: { country: Country; query: string }) => Promise<Suggestion[]>;
	hint?: string;
	label?: string;
	name: string;
	onChange: (details: { autocomplete: string; locationId: string }) => void;
	placeholder?: string;
	rules?: Omit<RegisterOptions, "deps">;
}

const PlacesAutocomplete = ({
	countryCode,
	flex,
	getPlaceSuggestions,
	hint,
	label,
	name,
	onChange,
	placeholder,
	rules
}: PlacesAutocompleteProps): JSX.Element => {
	const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
	const inputRef = useRef<HTMLTextAreaElement>();

	const debouncedSearch = useMemo(
		() =>
			debounce(async (val) => {
				if (val?.length >= 3) {
					const fetchedSuggestions = await getPlaceSuggestions({ country: countryCode, query: val });
					setSuggestions(fetchedSuggestions);
				} else {
					setSuggestions([]);
				}
			}, 300),
		[countryCode, getPlaceSuggestions]
	);

	const handleChange = useCallback(debouncedSearch, [debouncedSearch]);

	const onSelect = (item: Suggestion): void => {
		onChange({ autocomplete: item.label, locationId: item.id });
		(document.activeElement as HTMLElement).blur();
	};

	const width = inputRef?.current?.getBoundingClientRect()?.width;

	return (
		<Wrapper>
			<TextInput
				autoComplete="postal-code"
				customRef={inputRef}
				flex={flex}
				hint={hint}
				label={label}
				name={name}
				onCustomChange={handleChange}
				placeholder={placeholder}
				rules={rules}
			/>
			{!!suggestions?.length && (
				<SuggestionsWrapper $width={width}>
					{suggestions?.map((p) => (
						<SuggestionItem key={p.id} onClick={() => onSelect(p)} tabIndex={0}>
							{p.label}
						</SuggestionItem>
					))}
				</SuggestionsWrapper>
			)}
		</Wrapper>
	);
};

export default PlacesAutocomplete;
