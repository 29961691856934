import { useTranslation } from "react-i18next";
import { NavLink } from "react-router";
import styled from "styled-components";

const Wrapper = styled.div`
	height: 100%;
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	width: 300px;
	padding: 40px 0;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Text = styled.h4`
	color: var(--night-100);
`;

const StyledNavLink = styled(NavLink)`
	text-decoration: none;
	display: block;
	padding: 16px;
	margin-left: 28px;
	&:hover,
	&[aria-current] {
		${Text} {
			color: var(--lake-100);
		}
	}
	&[aria-current] {
		background-color: var(--lake-25);
		border-right: 4px solid var(--lake-100);
	}
`;

interface SidebarProps {
	routes: {
		id: string;
	}[];
}

const Sidebar = ({ routes }: SidebarProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			{routes.map((route) => {
				return (
					<StyledNavLink id={route.id} key={route.id} to={`/admin/${route.id}`}>
						<Text>{t(`routes.adminSidebar.${route.id}`)}</Text>
					</StyledNavLink>
				);
			})}
		</Wrapper>
	);
};

export default Sidebar;
