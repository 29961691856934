import { AmplitudeContext } from "@hygo/shared/amplitude";
import { Plus, UploadFile } from "@hygo/shared/icons";
import { Field, FieldsManagerEvents } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { DashboardMode } from "@hygo/web/models";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";

import Button from "../../Button";

const FooterWrapper = styled.div`
	background-color: var(--white-100);
	padding: 16px 30px;
	gap: 8px;
	display: flex;
	flex-direction: column;
`;

interface FooterProps {
	handleFieldSelection: (data: {
		centerMap: boolean;
		field: Field;
		overrideActiveFields: Field[];
		overrideNewSelectedFields: Field[];
		selection: boolean;
	}) => void;
	updateMode: (m: DashboardMode) => void;
}

const Footer = ({ handleFieldSelection, updateMode }: FooterProps): JSX.Element => {
	const navigate = useNavigate();
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { t } = useTranslation();

	const goToImport = (): void => {
		navigate("/import");
	};

	const onClickDraw = (): void => {
		logAnalyticEvent(FieldsManagerEvents.clickDrawNewField);
		handleFieldSelection({
			centerMap: false,
			field: null,
			overrideActiveFields: null,
			overrideNewSelectedFields: [],
			selection: true
		});
		updateMode(DashboardMode.NEW_FIELD);
	};

	return (
		<FooterWrapper>
			<Button
				color={COLORS.SMOKE}
				Icon={UploadFile}
				inverted
				onClick={goToImport}
				text={t("components.fieldsListCard.footer.import")}
			/>
			<Button
				color={COLORS.LAKE}
				Icon={Plus}
				onClick={onClickDraw}
				text={t("components.fieldsListCard.footer.newField")}
			/>
		</FooterWrapper>
	);
};

export default Footer;
