import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { Calendar, Close, Pencil, PulvEquipment, RecycleCrop, Trash, Zone } from "@hygo/shared/icons";
import { ModalsContext } from "@hygo/shared/modals";
import { Feature, Field, FieldsManagerEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS, convertToHa, formatDateToLocale, hexToRGBA } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { CreationMode, DashboardMode } from "@hygo/web/models";
import {
	ButtonCluster,
	FieldCharacteristicsModal,
	FieldNameModal,
	FieldPhasesModal,
	InputTip,
	Loader,
	MultiActionsButton,
	ParcelSVG,
	SimpleButton
} from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";

import MileosInputs from "./MileosInputs";

const Cross = styled(Close)`
	cursor: pointer;
	position: absolute;
	right: 4px;
	top: 4px;
`;

const ClusterWrapper = styled.div`
	background-color: var(--white-100);
	padding: 16px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const LabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

export const InlineWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const FieldName = styled.h3`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
`;

const FieldNameWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
`;

export const Link = styled(SimpleButton)<{ $highlight: boolean }>`
	color: ${(props) => (props.$highlight ? "var(--tangerine-100)" : "var(--lake-100)")};
	&:hover {
		color: ${(props) => (props.$highlight ? "var(--tangerine-hover)" : "var(--lake-hover)")};
	}
`;

export const Value = styled.h5`
	color: var(--night-50);
`;

const Wrapper = styled.div`
	overflow: auto;
	height: 100%;
	flex-direction: column;
	gap: 16px;
	display: flex;
`;

const Container = styled.div<{ $width: number }>`
	padding: 16px;
	background-color: var(--smoke-100);
	position: relative;
	width: ${(props) => props.$width}px;
	border-top: 1px solid var(--night-10);
`;

interface EditSingleFieldProps {
	selectedField: Field;
	width: number;
}
const EditSingleField = ({ selectedField, width }: EditSingleFieldProps): JSX.Element => {
	const { i18n, t } = useTranslation();
	const { crops, defaultFarm, loadFields, user } = useContext(UserContext);
	const navigate = useNavigate();
	const hasMileos = useFeature(Feature.MILEOS);
	const { showSnackbar } = useContext(SnackbarContext);
	const { showModal } = useContext(ModalsContext);
	const { deleteFields, patchFields } = useApi();
	const { formatFields, logAnalyticEvent } = useContext(AmplitudeContext);
	const { drawRef, handleFieldSelection, setCreationMode, setEditedFieldId, updateFieldCoordinates, updateMode } =
		useContext(CropsScreenContext);
	const [loading, setLoading] = useState<boolean>(false);
	const fieldCrop = crops?.find((c) => c.id === selectedField?.cropId);

	const onUpdateField = async (field: {
		area?: number;
		cropId?: number;
		harvestDate?: Date;
		id: number;
		name?: string;
		sowingDate?: Date;
	}): Promise<void> => {
		try {
			const data = await patchFields({
				farmId: defaultFarm.id,
				fields: [field]
			});
			const fetchedFields = await loadFields(false, defaultFarm?.id);
			const newField = fetchedFields?.find(
				(f) => f.id === (data?.createdFieldIds?.[0] || data?.updatedFieldIds?.[0])
			);
			handleFieldSelection({
				centerMap: true,
				field: null,
				overrideActiveFields: fetchedFields,
				overrideNewSelectedFields: [newField],
				selection: true
			});
			logAnalyticEvent(FieldsManagerEvents.updateField, {
				fields: formatFields([newField], crops)
			});
			showSnackbar(t("snackbar.updateField.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.updateField.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const onDeleteField = async (): Promise<void> => {
		setLoading(true);
		try {
			await deleteFields({ farmId: defaultFarm.id, fieldIds: [selectedField.id] });

			const fetchedFields = await loadFields(false, defaultFarm?.id);
			handleFieldSelection({
				centerMap: true,
				field: null,
				overrideActiveFields: fetchedFields,
				overrideNewSelectedFields: [],
				selection: true
			});
			updateMode(DashboardMode.FIELD_LIST);
			logAnalyticEvent(FieldsManagerEvents.deleteField, { fields: formatFields([selectedField], crops) });
			showSnackbar(t("snackbar.deleteField.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.deleteField.error"), SnackbarType.ERROR);
			throw e;
		} finally {
			setLoading(false);
		}
	};

	const showTasks = (): void => {
		logAnalyticEvent(FieldsManagerEvents.clickShowTasks, { fields: formatFields([selectedField], crops) });
		navigate("/interventions", { state: { selectedFieldId: selectedField.id } });
	};

	const onEditName = (): void => {
		logAnalyticEvent(FieldsManagerEvents.editFieldName);
		showModal(FieldNameModal, {
			field: selectedField,
			onSubmit: onUpdateField
		});
	};

	const onEditDates = (): void => {
		logAnalyticEvent(FieldsManagerEvents.editFieldPhases);
		showModal(FieldPhasesModal, {
			fields: [selectedField],
			onSubmit: ({ harvestDate, ids, sowingDate }) => onUpdateField({ harvestDate, id: ids[0], sowingDate })
		});
	};

	const onEditCharacteristics = (): void => {
		logAnalyticEvent(FieldsManagerEvents.editFieldCharacteristics);
		showModal(FieldCharacteristicsModal, {
			field: selectedField,
			onSubmit: onUpdateField
		});
	};

	const onEditZone = (): void => {
		logAnalyticEvent(FieldsManagerEvents.clickEditZone, { fields: formatFields([selectedField], crops) });
		setEditedFieldId(selectedField.id);
		if (selectedField?.coordinates?.length) {
			updateFieldCoordinates(selectedField?.coordinates, true);
			setCreationMode(CreationMode.DRAWING);
			drawRef.current.changeMode("direct_select", { featureId: "polygon" });
		} else {
			updateFieldCoordinates([], true);
			setCreationMode(CreationMode.RPG);
			drawRef.current.changeMode("static");
		}
		updateMode(DashboardMode.UPDATE_FIELD);
	};

	const onClose = (): void => {
		handleFieldSelection({
			centerMap: true,
			field: null,
			overrideActiveFields: null,
			overrideNewSelectedFields: [],
			selection: true
		});
		updateMode(DashboardMode.FIELD_LIST);
	};

	return (
		<Container $width={width}>
			<Cross height={20} onClick={onClose} width={20} />
			{loading ? (
				<Loader />
			) : (
				<Wrapper>
					<FieldNameWrapper>
						{selectedField?.coordinates && (
							<ParcelSVG
								fill={hexToRGBA(fieldCrop?.color, 0.5, COLORS.LAKE[50])}
								height={32}
								path={selectedField.svg}
								stroke={hexToRGBA(fieldCrop?.color, 1, COLORS.LAKE[100])}
								width={32}
							/>
						)}
						<FieldName>{selectedField?.name}</FieldName>
					</FieldNameWrapper>
					<ClusterWrapper>
						<MultiActionsButton>
							<ButtonCluster
								chevronColor={COLORS.LAKE[100]}
								Icon={<Pencil fill={COLORS.LAKE[100]} height={20} width={20} />}
								label={t("screens.dashboard.fieldDescriptionCard.rename")}
								onClick={onEditName}
							/>
							<ButtonCluster
								chevronColor={COLORS.LAKE[100]}
								Icon={<Zone fill={COLORS.LAKE[100]} height={20} width={20} />}
								label={t("screens.dashboard.editSingleField.drawFieldBtn")}
								onClick={onEditZone}
							/>
							<ButtonCluster
								chevronColor={COLORS.LAKE[100]}
								Icon={<PulvEquipment fill={COLORS.LAKE[100]} height={20} width={20} />}
								label={t("screens.dashboard.editSingleField.showTasksBtn")}
								onClick={showTasks}
							/>
							<ButtonCluster
								chevronColor={COLORS.GASPACHO[100]}
								Icon={<Trash fill={COLORS.GASPACHO[100]} height={20} width={20} />}
								label={t("screens.dashboard.editSingleField.deleteFieldBtn")}
								onClick={onDeleteField}
							/>
						</MultiActionsButton>
					</ClusterWrapper>
					<ClusterWrapper>
						<InlineWrapper>
							<h4>{t("screens.dashboard.fieldDescriptionCard.characteristics")}</h4>
							{(selectedField?.needCheck || !selectedField?.smagCropZoneUid) && (
								<Link
									$highlight={!selectedField?.cropId || !selectedField?.area}
									onClick={onEditCharacteristics}
									text={t("button.edit")}
								/>
							)}
						</InlineWrapper>
						<InlineWrapper>
							<LabelWrapper>
								<Zone
									fill={selectedField?.area ? COLORS.LAKE[100] : COLORS.NIGHT[50]}
									height={20}
									width={20}
								/>
								<h5>{t("inputs.area.label")}</h5>
							</LabelWrapper>
							<Value>
								{selectedField?.area ? `${convertToHa(selectedField.area)} ${t("units.hectare")}` : "-"}
							</Value>
						</InlineWrapper>
						<InlineWrapper>
							<LabelWrapper>
								<RecycleCrop
									fill={fieldCrop?.id ? COLORS.LAKE[100] : COLORS.NIGHT[50]}
									height={20}
									width={20}
								/>
								<h5>{t("inputs.crop.label")}</h5>
							</LabelWrapper>
							<Value>{fieldCrop?.name || "-"}</Value>
						</InlineWrapper>
						{!selectedField?.needCheck && !!selectedField?.smagCropZoneUid && (
							<InputTip palette={COLORS.DISABLED} withBorder={false}>
								<ColoredCardText>
									{t("screens.dashboard.fieldDescriptionCard.smagCrop.tooltip")}
								</ColoredCardText>
							</InputTip>
						)}
					</ClusterWrapper>
					<ClusterWrapper>
						<InlineWrapper>
							<h4>{t("screens.dashboard.fieldDescriptionCard.fieldPhases")}</h4>
							<Link
								$highlight={!selectedField?.sowingDate || !selectedField?.harvestDate}
								onClick={onEditDates}
								text={t("button.edit")}
							/>
						</InlineWrapper>
						<InlineWrapper>
							<LabelWrapper>
								<Calendar
									fill={selectedField?.sowingDate ? COLORS.LAKE[100] : COLORS.NIGHT[50]}
									height={20}
									width={20}
								/>
								<h5>{t("inputs.fieldPhases.sowingDate.label")}</h5>
							</LabelWrapper>
							<Value>
								{selectedField?.sowingDate
									? formatDateToLocale(
											new Date(selectedField?.sowingDate),
											`${i18n.resolvedLanguage}-${user?.countryCode}`
										)
									: "-"}
							</Value>
						</InlineWrapper>
						<InlineWrapper>
							<LabelWrapper>
								<Calendar
									fill={selectedField?.harvestDate ? COLORS.LAKE[100] : COLORS.NIGHT[50]}
									height={20}
									width={20}
								/>
								<h5>{t("inputs.fieldPhases.harvestDate.label")}</h5>
							</LabelWrapper>
							<Value>
								{selectedField?.harvestDate
									? formatDateToLocale(
											new Date(selectedField?.harvestDate),
											`${i18n.resolvedLanguage}-${user?.countryCode}`
										)
									: "-"}
							</Value>
						</InlineWrapper>
					</ClusterWrapper>
					{!!hasMileos && !!selectedField?.mileosStatus && <MileosInputs field={selectedField} />}
				</Wrapper>
			)}
		</Container>
	);
};

export default EditSingleField;
