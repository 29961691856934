import { useApi } from "@hygo/shared/api";
import { Coop } from "@hygo/shared/models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface useCoopsProps {
	coopId?: number;
}

interface useCoopsResult {
	coops: Coop[];
	matchingCoop: Coop;
}

export const useCoops = ({ coopId }: useCoopsProps): useCoopsResult => {
	const { getCoops } = useApi();
	const { i18n } = useTranslation();
	const [coops, setCoops] = useState<useCoopsResult["coops"]>([]);

	useEffect(() => {
		const getCoopsList = async (): Promise<void> => {
			const fetchedCoops = await getCoops();
			const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
			const fetchedCoopsSorted = fetchedCoops?.sort((a, b) => collator.compare(a.name, b.name));
			setCoops(fetchedCoopsSorted);
		};
		getCoopsList();
	}, [getCoops, i18n]);

	const matchingCoop = coops?.find((c) => c.id === coopId);

	return {
		coops,
		matchingCoop
	};
};
