import { useApi } from "@hygo/shared/api";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import EmailPasswordValidationScreen from "./EmailPasswordValidationScreen";
import { EmailPasswordValidationScreenProps } from "./screen.types";

const EmailPasswordValidationContainer = (): JSX.Element => {
	const { checkEmailAndSendPasswordReset } = useApi();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const methods = useForm({ mode: "onChange" });
	const [loading, setLoading] = useState<EmailPasswordValidationScreenProps["loading"]>(false);
	const [emailSent, setEmailSent] = useState<EmailPasswordValidationScreenProps["emailSent"]>(false);

	const onSubmit: EmailPasswordValidationScreenProps["onSubmit"] = async ({ email }) => {
		setLoading(true);
		try {
			await checkEmailAndSendPasswordReset(email);
			setEmailSent(true);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
		} catch (e) {
			methods.setError("email", { message: t("inputs.email.errors.invalid"), type: "custom" });
		} finally {
			setLoading(false);
		}
	};

	const onGoBack: EmailPasswordValidationScreenProps["onGoBack"] = (): void => {
		navigate(-1);
	};

	return (
		<EmailPasswordValidationScreen
			emailSent={emailSent}
			loading={loading}
			methods={methods}
			onGoBack={onGoBack}
			onSubmit={onSubmit}
		/>
	);
};

export default EmailPasswordValidationContainer;
