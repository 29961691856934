import { AmplitudeEvent, Crop, Field } from "@hygo/shared/models";
import _ from "lodash";
import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import FieldsList from "./FieldsList";

const Wrapper = styled.div`
	flex: 1;
	overflow: auto;
`;

interface FieldGroup {
	cropId: string;
	cropName: string;
	data: Field[];
}

interface FieldsTreeViewProps {
	crops: Crop[];
	fields: Field[];
	fieldsRef?: { [x: number]: RefObject<HTMLDivElement> };
	handleFieldSelection: (data: {
		centerMap: boolean;
		field: Field;
		overrideActiveFields: Field[];
		overrideNewSelectedFields: Field[];
		selection: boolean;
	}) => void;
	multiSelectionEnabled: boolean;
	selectedFields?: Field[];
	selectFieldEvent: AmplitudeEvent;
	withNeedCheck: boolean;
}

const FieldsTreeView = ({
	crops,
	fields,
	fieldsRef,
	handleFieldSelection,
	multiSelectionEnabled,
	selectedFields,
	selectFieldEvent,
	withNeedCheck
}: FieldsTreeViewProps): JSX.Element => {
	const { i18n, t } = useTranslation();
	const collator = useMemo(
		() => new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" }),
		[i18n]
	);

	const sortedFieldsGroupedByCrops: FieldGroup[] = useMemo(() => {
		const fieldsGroupedByCrops = _.groupBy(
			fields?.filter((f) => !f.needCheck),
			"cropId"
		);
		return Object.keys(fieldsGroupedByCrops)
			?.map((cropId) => {
				const cropName = crops.find((c) => c.id.toString() === cropId)?.name;
				return {
					cropId,
					cropName,
					data: fieldsGroupedByCrops[cropId]
				};
			})
			?.sort((a, b) => collator.compare(a.cropName, b.cropName));
	}, [fields, crops, collator]);

	const fieldsWithNeedCheck = withNeedCheck && fields?.filter((f) => f.needCheck);

	const needCheckToCrop: Crop = {
		color: null,
		id: 0,
		mileosCompatible: false,
		name: t("crops.needCheck"),
		svg: `<svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5ZM12 6C11.8096 5.99984 11.6212 6.0394 11.4469 6.11615C11.2726 6.1929 11.1162 6.30516 10.9878 6.44575C10.8593 6.58635 10.7616 6.75218 10.7009 6.93268C10.6401 7.11317 10.6177 7.30435 10.635 7.494L11.1825 13.503C11.2037 13.705 11.299 13.892 11.45 14.028C11.6009 14.1639 11.7969 14.2391 12 14.2391C12.2031 14.2391 12.3991 14.1639 12.55 14.028C12.701 13.892 12.7963 13.705 12.8175 13.503L13.3635 7.494C13.3808 7.30447 13.3584 7.11342 13.2978 6.93303C13.2371 6.75264 13.1395 6.58688 13.0112 6.4463C12.8829 6.30573 12.7267 6.19344 12.5526 6.1166C12.3785 6.03976 12.1903 6.00005 12 6ZM12 18C12.3183 18 12.6235 17.8736 12.8485 17.6485C13.0736 17.4235 13.2 17.1183 13.2 16.8C13.2 16.4817 13.0736 16.1765 12.8485 15.9515C12.6235 15.7264 12.3183 15.6 12 15.6C11.6817 15.6 11.3765 15.7264 11.1515 15.9515C10.9264 16.1765 10.8 16.4817 10.8 16.8C10.8 17.1183 10.9264 17.4235 11.1515 17.6485C11.3765 17.8736 11.6817 18 12 18Z"/></svg>
`
	};

	return (
		<Wrapper>
			{fieldsWithNeedCheck?.length > 0 && (
				<FieldsList
					crop={needCheckToCrop}
					crops={crops}
					fields={fieldsWithNeedCheck}
					fieldsRef={fieldsRef}
					handleFieldSelection={handleFieldSelection}
					needCheck
					selectedFields={selectedFields}
					selectFieldEvent={selectFieldEvent}
					withCheckbox={multiSelectionEnabled}
				/>
			)}
			{sortedFieldsGroupedByCrops.map((fieldGroup) => {
				const crop = crops.find((c) => c.id.toString() === fieldGroup.cropId);
				return (
					<FieldsList
						crop={crop}
						crops={crops}
						fields={fieldGroup.data}
						fieldsRef={fieldsRef}
						handleFieldSelection={handleFieldSelection}
						key={fieldGroup.cropId}
						selectedFields={selectedFields}
						selectFieldEvent={selectFieldEvent}
						withCheckbox={multiSelectionEnabled}
					/>
				);
			})}
		</Wrapper>
	);
};

export default FieldsTreeView;
