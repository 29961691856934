import { Logo } from "@hygo/shared/icons";
import { CoopUser, User } from "@hygo/shared/models";
import { Route } from "@hygo/web/models";
import { useNavigate } from "react-router";
import styled from "styled-components";

import UserMenu from "../UserMenu";
import UserSelector from "../UserSelector";
import NavItem from "./components/NavItem";

interface NavbarProps {
	admin: boolean;
	coopUsers: CoopUser[];
	farmerSelected: boolean;
	logout: () => void;
	routes: Route[];
	signInAsUser: (val: number) => Promise<void>;
	superAdmin: boolean;
	user: User;
}

const Wrapper = styled.nav`
	width: 100%;
	height: 68px;
	display: flex;
	justify-content: space-between;
	background-color: var(--white-100);
	position: relative;
	z-index: 1;
	padding-right: 16px;
`;

const LogoWrapper = styled.div`
	margin-right: 4px;
	width: 132px;
	height: 69px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	border-bottom-right-radius: 8px;
	cursor: pointer;
`;

const LinksWrapper = styled.div`
	display: flex;
	padding-top: 10px;
	flex: 1;
`;

const UserSelectorWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 0.4;
	margin-right: 16px;
`;

const Navbar = ({
	admin,
	coopUsers,
	farmerSelected,
	logout,
	routes,
	signInAsUser,
	superAdmin,
	user
}: NavbarProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Wrapper>
			<LogoWrapper onClick={() => navigate("/")} onKeyDown={() => navigate("/")} role="button" tabIndex={0}>
				<Logo />
			</LogoWrapper>
			<LinksWrapper>
				{farmerSelected &&
					routes.map((route) => <NavItem key={route.id} route={route} superAdmin={superAdmin} />)}
			</LinksWrapper>
			{admin && (
				<UserSelectorWrapper>
					<UserSelector coopUsers={coopUsers} signInAsUser={signInAsUser} user={user} />
				</UserSelectorWrapper>
			)}
			<UserMenu admin={admin} farmerSelected={farmerSelected} logout={logout} user={user} />
		</Wrapper>
	);
};

export default Navbar;
