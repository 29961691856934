import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { ModalsContext } from "@hygo/shared/modals";
import { SnackbarType, TasksEvents } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { ExportTasksModal } from "@hygo/web/ui-components";
import { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import ExportLandingScreen from "./ExportLandingScreen";
import { ExportLandingScreenProps } from "./screen.types";

const ExportLandingContainer = (): JSX.Element => {
	const { defaultFarm, farms, fields: allFields, updateDefaultFarm, user } = useContext(UserContext);
	const { t } = useTranslation();
	const { exportTasks } = useApi();
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { showModal } = useContext(ModalsContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const [exporting, setExporting] = useState<ExportLandingScreenProps["exporting"]>(false);
	const [error, setError] = useState<ExportLandingScreenProps["error"]>(null);
	const navigate = useNavigate();

	const defaultValues = useMemo(
		() => ({ startAfter: new Date(user?.createdAt), startBefore: new Date(), withMeteo: false }),
		[user]
	);

	const methods = useForm({ defaultValues });

	const goToDashboard: ExportLandingScreenProps["goToDashboard"] = () => navigate("/");

	const onExportCampaign: ExportLandingScreenProps["onExportCampaign"] = () => {
		showModal(ExportTasksModal, {
			isGeofoliaExportable: allFields?.some((f) => f?.geofoliaReady),
			onConfirm: async (format: "dap" | "pdf" | "xlsx"): Promise<void> => {
				setExporting(true);
				try {
					const data = await exportTasks({
						farmId: defaultFarm.id,
						format,
						startAfter: methods.watch().startAfter,
						startBefore: methods.watch().startBefore,
						withMeteo: methods.watch().withMeteo
					});
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute(
						"download",
						`${defaultFarm.name}_${methods
							.watch()
							.startAfter.getFullYear()
							.toString()
							.slice(-2)}-${methods.watch().startBefore.getFullYear().toString().slice(-2)}.${format}`
					);
					document.body.appendChild(link);
					logAnalyticEvent(
						format === "dap" ? TasksEvents.exportTasksFileEdi : TasksEvents.exportTasksFileXsl
					);
					link.click();
					showSnackbar(t("snackbar.exportTasks.success"), SnackbarType.SUCCESS);
				} catch (e) {
					const reponseTxt = await e?.response?.data?.text();
					const code = JSON.parse(reponseTxt)?.code;
					setError(code);
					if (code !== "noTaskOnThisPeriod") {
						showSnackbar(t("snackbar.exportTasks.error.unknown"), SnackbarType.ERROR);
						throw e;
					}
				} finally {
					setExporting(false);
				}
			}
		});
	};

	const onWithMeteoPress: ExportLandingScreenProps["onWithMeteoPress"] = (value) =>
		methods.setValue("withMeteo", value, { shouldDirty: true, shouldValidate: true });

	const onUpdateDefaultFarm: ExportLandingScreenProps["updateDefaultFarm"] = async (farmId, newFarms) => {
		logAnalyticEvent(TasksEvents.updateDefaultFarmFromTasks, { farm: farms?.find((f) => f.id === farmId) });
		await updateDefaultFarm(farmId, newFarms);
	};

	return (
		<ExportLandingScreen
			defaultFarm={defaultFarm}
			error={error}
			exporting={exporting}
			farms={farms}
			goToDashboard={goToDashboard}
			methods={methods}
			onExportCampaign={onExportCampaign}
			onWithMeteoPress={onWithMeteoPress}
			updateDefaultFarm={onUpdateDefaultFarm}
			user={user}
		/>
	);
};

export default ExportLandingContainer;
