import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { BaseNozzle, Nozzle, NozzleFamily, SnackbarType, SprayerEvents } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { useContext, useMemo } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface useNozzleProps {
	nozzle?: Nozzle;
}

interface useNozzleResult {
	methods: UseFormReturn<BaseNozzle>;
	onCreateOrUpdate: (nozzle: Nozzle) => Promise<number>;
	onDelete: (nozzle: Nozzle) => Promise<void>;
	rules: Record<keyof BaseNozzle, Omit<RegisterOptions, "deps">>;
}

export const useNozzle = ({ nozzle }: useNozzleProps): useNozzleResult => {
	const { t } = useTranslation();
	const { createNozzle, deleteNozzle, updateNozzle } = useApi();
	const { activeNozzles, activeSprayers, loadNozzles, loadSprayers } = useContext(UserContext);
	const { formatNozzle, logAnalyticEvent } = useContext(AmplitudeContext);
	const { showSnackbar } = useContext(SnackbarContext);

	const defaultValues = useMemo(() => {
		return {
			color: nozzle?.color,
			family: nozzle?.family || NozzleFamily.CLASSIC_STD,
			height: nozzle?.height || 70,
			name: nozzle?.name || t("inputs.nozzleName.defaultValue", { position: (activeNozzles?.length || 0) + 1 }),
			pressure: nozzle?.pressure || 2,
			speed: nozzle?.speed || 10,
			sprayerId: nozzle?.sprayerId
		};
	}, [nozzle, t, activeNozzles]);

	const methods = useForm({ defaultValues, mode: "onChange" });

	const rules: useNozzleResult["rules"] = {
		color: {
			required: {
				message: t("inputs.nozzleCharacteristics.errors.required"),
				value: true
			}
		},
		family: {
			required: {
				message: t("inputs.nozzleType.errors.required"),
				value: true
			}
		},
		height: {
			required: {
				message: t("inputs.nozzleHeight.errors.required"),
				value: true
			},
			setValueAs: (v: string) => Number(v) || v,
			validate: (value: number) => {
				return value > 0 || t("inputs.nozzleHeight.errors.invalid");
			}
		},
		name: {
			required: {
				message: t("inputs.nozzleName.errors.required"),
				value: true
			}
		},
		pressure: {
			required: {
				message: t("inputs.nozzlePressure.errors.required"),
				value: true
			},
			setValueAs: (v: string) => Number(v) || v,
			validate: (value: number) => {
				return value > 0 || t("inputs.nozzlePressure.errors.invalid");
			}
		},
		speed: {
			required: {
				message: t("inputs.nozzleSpeed.errors.required"),
				value: true
			},
			setValueAs: (v: string) => Number(v) || v,
			validate: (value: number) => {
				return value > 0 || t("inputs.nozzleSpeed.errors.invalid");
			}
		},
		sprayerId: undefined
	};

	const onCreateOrUpdate: useNozzleResult["onCreateOrUpdate"] = async (values) => {
		try {
			const sprayerName = activeSprayers?.find((s) => s.id === values.sprayerId)?.name;
			let createdId;
			const { color, family, height, name, pressure, speed, sprayerId } = values;
			if (!values?.id) {
				const { id } = await createNozzle({ color, family, height, name, pressure, speed, sprayerId });
				createdId = id;
				logAnalyticEvent(
					SprayerEvents.addNozzle,
					formatNozzle({ ...nozzle, deleted: null, id: null }, sprayerName)
				);
			} else {
				await updateNozzle(values);
				logAnalyticEvent(SprayerEvents.updateNozzle, formatNozzle(values, sprayerName));
			}
			methods.reset({
				color,
				family,
				height,
				name,
				pressure,
				speed,
				sprayerId
			});
			await loadNozzles();
			await loadSprayers();
			showSnackbar(t("snackbar.nozzle.success"), SnackbarType.SUCCESS);
			return createdId;
		} catch (e) {
			showSnackbar(t("snackbar.nozzle.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const onDelete: useNozzleResult["onDelete"] = async (nozzleParams) => {
		try {
			await deleteNozzle({ nozzleId: nozzleParams?.id, sprayerId: nozzleParams?.sprayerId });
			await loadNozzles();
			await loadSprayers();
			const sprayerName = activeSprayers?.find((s) => s.id === nozzleParams.sprayerId)?.name;
			logAnalyticEvent(SprayerEvents.deleteNozzle, formatNozzle(nozzleParams, sprayerName));
			showSnackbar(t("snackbar.deleteNozzle.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.deleteNozzle.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	return {
		methods,
		onCreateOrUpdate,
		onDelete,
		rules
	};
};
