import { convertToFloat } from "./convertToFloat";

export const valueIsBetween = (value: number, minValue: number, maxValue: number): boolean =>
	value < maxValue && value > minValue;

export const countDecimals = (value: number): number => {
	if (value % 1 !== 0 && typeof value === "number") return value.toString().split(".")[1]?.length;
	return 0;
};

export const smallerThan = (value: number, limitValue: number): string => {
	if (value || value === 0)
		return value && value < limitValue ? `< ${limitValue}` : convertToFloat(value?.toFixed(1))?.toString();
};
